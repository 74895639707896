import { snakeCase } from 'lodash';

export const MaskCharacter = (str, mask = 'X', n = 3) =>
  `0${str[0]}${`${str}`.slice(1, -n).replace(/./g, mask)}${`${str}`.slice(-n)}`;

export const createFullName = (values) =>
  `${values?.first_name || ''} ${values?.last_name || ''} ${
    values?.surname || ''
  }`;

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

export const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const pythonObject = (obj) => {
  const newObject = Object.entries(obj).map(([key, value]) => [
    snakeCase(key),
    value
  ]);
  return newObject.reduce((curr, [k, v]) => ({ ...curr, [k]: v }), {});
};

export const downloadFile = (file, name) => {
  const blob = new Blob([file]);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const buildImagePath = (path) => {
  const mediaHost = process.env.REACT_APP_HOST;
  // test for complete url
  const fullPathPattern = /^(http)s*:\/\/.*\/.*/;
  if (fullPathPattern.test(path)) {
    const regex = /.*(api\/v1\/media\/.*\/.*)/;
    // replace domain with host domain
    const match = path?.match(regex);
    if (match) {
      return `${mediaHost}/${match[1]}`;
    }
  }
  return `${mediaHost}${path}`;
};

export const getStatusColor = (status) =>
  // eslint-disable-next-line no-nested-ternary
  status === 'PENDING'
    ? 'default'
    : status === 'REJECTED'
    ? 'error'
    : 'secondary';
